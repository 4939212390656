<template>
    <div class="main">
        <div class="w12 whole">
            <div class="left">
                <div class="head">
                    <img :src="userinfo.avatar" alt="">
                </div>
                <div class="nickname">
                    {{ userinfo.nickName }}
                </div>
                <div class="renzheng">
                    {{ userinfo.utypeValue }}
                </div>
                <div class="navList1 navList" style="margin: 0 auto;">
                    <el-col :span="12">

                        <el-menu :default-active="defaultactive" class="el-menu-vertical-demo" @select="select"
                            @open="handleOpen" @close="handleClose" text-color="#333333" active-text-color="#00B079">
                            <!-- <router-link to="/personal"> <el-menu-item index="1">
                                    <img style="width: 20px;height: 20px;margin-right: 10px;" src="@/assets/person/ren1.png"
                                        alt="" v-if="defaultactive == 1">
                                    <img style="width: 20px;height: 20px;margin-right: 10px;" src="@/assets/person/ren.png"
                                        alt="" v-else>
                                    账号设置
                                </el-menu-item>
                            </router-link> -->
                            <el-menu-item index="1" @click="goMenu(0)">
                                <img style="width: 20px;height: 20px;margin-right: 10px;" src="@/assets/person/ren1.png"
                                    alt="" v-if="defaultactive == 1">
                                <img style="width: 20px;height: 20px;margin-right: 10px;" src="@/assets/person/ren.png"
                                    alt="" v-else>
                                账号设置
                            </el-menu-item>
                            <el-submenu index="2" :class="{ 'on': defaultactive == 2 }">
                                <template slot="title">
                                    <img style="width: 20px;height: 20px;margin-right: 10px;" src="@/assets/person/fj1.png"
                                        alt="" v-if="defaultactive == 2">
                                    <img style="width: 20px;height: 20px;margin-right: 10px;" src="@/assets/person/fj.png"
                                        alt="" v-else>
                                    <span style="font-size:18px;">我的发布</span>
                                </template>
                                <el-menu-item-group class="menuList">
                                    <!-- <router-link to="/rentList">
                                        <el-menu-item index="1-1">仓库租赁</el-menu-item>
                                    </router-link>
                                    <router-link to="/recList">
                                        <el-menu-item index="1-2">岗位招聘</el-menu-item>
                                    </router-link>
                                    <router-link to="/askList">
                                        <el-menu-item index="1-3">仓库求租</el-menu-item>
                                    </router-link> -->
                                    <el-menu-item @click="goMenu(1)" index="1-1">仓库出租</el-menu-item>
                                    <el-menu-item @click="goMenu(2)" index="1-2">岗位招聘</el-menu-item>
                                    <el-menu-item @click="goMenu(3)" index="1-3">仓库求租</el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>

                            <div @click="goMenu(4)" class="map-btn-inner" v-if="isShowBtn">
                              <i class="el-icon-location" style="color: #333333; font-size: 24px; margin-right: 5px;"></i>
                              <span>集团地图</span>
                            </div>

                            <el-menu-item index="3" class="tuichu" style="margin-top: 140px;" @click="tuichuDl">
                                <img style="width: 20px;height: 20px;margin-right: 10px;" src="@/assets/person/sz.png"
                                    alt="">
                                退出登录
                            </el-menu-item>

                        </el-menu>
                    </el-col>

                </div>

            </div>
            <div class="right">
                <router-view></router-view>
            </div>
        </div>






    </div>
</template>
<script>
export default {

    data() {
        let that = this;
        return {
            userinfo: {},
            defaultactive: '1',
            isShowBtn: false
        };
    },

    mounted() {
      
      this.$api.base.check_group_user().then((res, code) => {
          console.log( res )
          if ( res.code == 200 ) {
            this.isShowBtn = res.data;
          } else {
            this.isShowBtn = false;
            this.$alert('当前登录状态已失效，请重新登录', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                localStorage.removeItem('token')
                this.$router.push("/")
              }
            });
          }
        })
        // location.reload();
        let menu = localStorage.getItem("menu")
        if (menu == 2) {
            this.defaultactive = menu
        }

    },
    created() {
        console.log(this.$route.query.token);
        if (this.$route.query.token) {
            localStorage.setItem("token", this.$route.query.token)
            this.$router.push({
                path: "/personal"
            });
            location.reload();
        } else if (localStorage.getItem("token") == undefined || localStorage.getItem("token") == '') {
            this.$router.push({
                path: "/"
            });
            return
        }





        this.getUser()
    },
    methods: {
        goMenu(index) {
            if (index == 0) {
                this.$router.push({
                    path: "/personal"
                });
                localStorage.setItem("menu", 1)
            } else if (index == 1) {
                this.$router.push({
                    path: "/rentList"
                });
                localStorage.setItem("menu", 2)
            } else if (index == 2) {
                this.$router.push({
                    path: "/recList"
                });
                localStorage.setItem("menu", 2)
            } else if (index == 3) {
                this.$router.push({
                    path: "/askList"
                });
                localStorage.setItem("menu", 2)
            }else if (index == 4) {
                this.$router.push({
                    path: "/allMap"
                });
                localStorage.setItem("menu", 4)
            }
        },
        async tuichuDl() {
            let data = await this.$api.base.logout()
            console.log(data);
            localStorage.removeItem('token')
            this.$router.push('/')
            setTimeout(() => {
                location.reload();
            }, 500)
        },
        // 获取用户数据
        async getUser() {
            let user = await this.$api.base.getUser()
            this.userinfo = user.info
            console.log(this.userinfo);
            if (this.userinfo == undefined) {
                if (user.code == -1) {
                    localStorage.removeItem('token')
                    this.$router.push('/')
                    setTimeout(() => {
                        location.reload();
                    }, 500)
                }
            }
            console.log(user);


        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
            if (key == 2) {
                this.defaultactive = '2'
            }
        },
        select(key, keyPath) {
            console.log(key, keyPath, '1111');

            if (keyPath[0] == '2') {
                this.defaultactive = '2'
            } else {
                this.defaultactive = '1'
            }
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
            if (key == 2) {
                this.defaultactive = '2'
            }
        },

    },
};
</script>
<style scoped lang="less">

.map-btn-inner {
  padding: 30px 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/deep/ .el-dialog {

    border-radius: 10px !important;
}

/deep/ .v-modal {
    z-index: 1000 !important;
}

.is-active {
    background-color: #E5F7F2;
    border-radius: 10px !important;
}

/deep/ .on .el-submenu__title {

    background-color: #E5F7F2;
    border-radius: 10px !important;
}

/deep/ .el-menu-item {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

/deep/ .el-submenu__title {
    display: flex;
    justify-content: center;
    align-items: center;
}

/deep/ .el-submenu__icon-arrow {
    display: none;
}

/deep/ .el-menu {

    border: none;
}

/deep/ .el-col-12 {
    width: 100%;

}

/deep/ .el-menu {

    padding: 0 50px;
}

/deep/ .el-menu-item:focus,
.el-menu-item:hover {
    border-radius: 10px !important;
    background-color: #E5F7F2 !important;
}

/deep/ .el-submenu__title:hover {
    border-radius: 10px !important;
    background-color: #E5F7F2;
}

/deep/ .el-submenu__title:focus,
.el-submenu__title:hover {
    border-radius: 10px !important;
    background-color: #E5F7F2 !important;
}

/deep/ .el-submenu__title * {
    font-size: 14px;
}

/deep/ .el-submenu .el-menu-item {
    min-width: 100px;
    margin: 0;
    padding: 0px !important;
    text-align: center !important;
}

.tuichu {
    background-color: rgba(153, 153, 153, 0.1);
    border-radius: 10px;

}

.whole {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;

    .left {
        width: 25%;

        .head {
            margin: 0 auto;
            width: 188px;
            height: 188px;
            padding: 12px;
            border-radius: 50%;
            overflow: hidden;

            img {
                border-radius: 50%;
                overflow: hidden;
                width: 188px;
                height: 188px;
            }
        }

        .nickname {
            text-align: center;
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 23px;
            margin-top: 10px;

        }

        .renzheng {
            text-align: center;
            width: 104px;
            height: 30px;
            line-height: 30px;
            background: #00B079;
            border-radius: 20px 20px 20px 20px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin: 10px auto 50px auto;
        }

        .navList {
            .nav {
                display: flex;
                align-items: center;

                .nav_ico {
                    img {
                        width: 20px;
                        height: 20px;
                    }

                    margin-right: 10px;
                }

                .nav_con {
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21px;
                }
            }
        }

        .navList1 {
            padding-top: 50px;
            border-top: 1px solid #CCCCCC;
        }

        .navList2 {}
    }

    .right {
        width: 70%;

    }
}

.menuList {
    /deep/ .is-active[data-v-1abc445a] {
        background-color: #FFFFFF !important;
        color: #333333 !important;
        font-weight: 600;
    }

    /deep/ .el-menu-item:hover {
        border-radius: 10px !important;
        background-color: #FFFFFF !important;
    }
}
</style>
